import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Content = styled.header`
  margin: 0 0 24px;
  background: red;
  color: white;
`;
const HeaderLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:visited {
    color: white;
  }
`;
const HeaderHeading = styled.h1`
  margin: 0;
  padding: 24px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
`;

const Header = ({ siteTitle }) => (
  <Content>
    <HeaderHeading>
      <HeaderLink to="/">{siteTitle}</HeaderLink>
    </HeaderHeading>
  </Content>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;

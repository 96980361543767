import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import useSiteMetadata from '../hooks/use-site-metadata';
import Header from './header';

const GlobalStyle = createGlobalStyle`
  /* apply a natural box layout model to all elements, but allowing components to change */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
`;

const Layout = ({ children }) => {
  const { title } = useSiteMetadata();

  return (
    <>
      <GlobalStyle />
      <Header siteTitle={title} />
      <div>
        <main>{children}</main>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
